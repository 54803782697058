import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { useEffect } from "react";
import { addMinutes, formatDistanceToNow } from "date-fns";
import aloisLogo from "../../../assets/images/logo/alois-logo.svg";
import ApplyNowForm from "./applyNowform";

const JobVacancyList = ({ jobVacancyList }) => {
  const [jobId, setJobId] = useState("");
  const [isApplyFormOpen, setIsApplyFormOpen] = useState(false);
  const [loading, setLoading] = useState(true); // Loading state
  const [hasError, setHasError] = useState(false); 

 
useEffect(() => {
  const fetchJobs = async () => {
    setLoading(true);
    setHasError(false);
    try {
      await new Promise((resolve, reject) => {
        const success = true; 
        setTimeout(() => {
          success ? resolve() : reject(new Error("Failed to fetch jobs"));
        }, 500);
      });
    } catch (error) {
      console.error(error);
      setHasError(true);
    } finally {
      setLoading(false);
    }
  };
  fetchJobs();
}, []);
  

  const openApplyForm = () => {
    setIsApplyFormOpen(true);
  };

  const closeApplyform = () => {
    setIsApplyFormOpen(false);
  };

  const updateModalStateByChild = (value) => {
    setIsApplyFormOpen(value);
  };

  const handleApply = (id) => {
    console.log(isApplyFormOpen);
    openApplyForm();
    setJobId(id);
  };

  const getTimeAgo = (createdAt) => {
    const date = new Date(createdAt);

    // IST is GMT+5:30 which is 330 minutes ahead
    const dateIST = addMinutes(date, 330);

    // Get the "time ago" string
    return formatDistanceToNow(dateIST, { addSuffix: true });
  };

  return (
    <React.Fragment>
      <div>
        {loading ? (
          <div className="loader">
            <div className="spinner"></div>
          </div>
        ) : hasError || !jobVacancyList? (
          <div className="mt-4">No Jobs Found</div>
        ) : jobVacancyList?.length > 0 ? (
          jobVacancyList.map((jobVacancyListDetails, key) => (
            <div
              key={key}
              className={
                jobVacancyListDetails.addclassNameBookmark === true
                  ? "job-box bookmark-post card mt-4"
                  : "job-box card mt-4"
              }
            >
              {/* <div className="bookmark-label text-center" onClick={handleClick}>
              <Link to="#" className="align-middle text-white">
                <i className="mdi mdi-star"></i>
              </Link>
            </div> */}

              <div className="p-4">
                <Row>
                  <Col md={2}>
                    <div className="text-center mb-4 mb-md-0">
                      <Link to="/companydetails">
                        <img
                          src={aloisLogo}
                          alt="company-logo"
                          className="img-fluid rounded-3"
                        />
                      </Link>
                    </div>
                  </Col>

                  <Col md={3}>
                    <div className="mb-2 mb-md-0">
                      <h5 className="fs-18 mb-0">
                        <Link
                          to={`/jobdetails/${jobVacancyListDetails._id}`}
                          className="text-dark"
                        >
                          {jobVacancyListDetails.title}
                        </Link>
                      </h5>
                      <p className="text-muted fs-14 mb-0">
                        {jobVacancyListDetails.companyName}
                      </p>
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="d-flex mb-2">
                      <div className="flex-shrink-0">
                        <i className="mdi mdi-map-marker text-primary me-1"></i>
                      </div>
                      <p className="text-muted mb-0">
                        {[
                          jobVacancyListDetails.city,
                          jobVacancyListDetails.state,
                          jobVacancyListDetails.country,
                        ]
                          .filter(Boolean) // Filter out empty values
                          .map((part, index, array) => (
                            // Add <br /> between parts except the last one
                            <React.Fragment key={index}>
                              {part}
                              {index < array.length - 1 && ","}
                              {index < array.length - 1 && <br />}
                            </React.Fragment>
                          ))}
                      </p>
                    </div>
                  </Col>


                  <Col md={3}>
                    <div className="d-flex mb-0">
                      <div className="flex-shrink-0">
                        <i className="uil uil-clock-three text-primary me-1"></i>
                      </div>
                      <p className="text-muted mb-0">
                        {getTimeAgo(jobVacancyListDetails.createdAt)}
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="p-3 bg-light-gray">
                <Row className="justify-content-between">
                  <Col md={4}>
                    <div>
                      <p className="text-muted mb-0">
                        <span className="text-dark">Experience: </span>
                        {jobVacancyListDetails.minExp}-
                        {jobVacancyListDetails.maxExp} Years
                      </p>
                    </div>
                  </Col>
                  <Col lg={2} md={3}>
                    <div>
                      <Link
                        to="#applyNow"
                        onClick={() => handleApply(jobVacancyListDetails._id)}
                        className="primary-link"
                      >
                        Apply Now{" "}
                        <i className="mdi mdi-chevron-double-right"></i>
                      </Link>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          ))
        ) : (
          // <div className="mt-4">No Jobs Found</div>
          <div className="loader">
            <div className="spinner"></div>
          </div>
        )}
        {isApplyFormOpen && (
          <ApplyNowForm
            show={isApplyFormOpen}
            onClose={closeApplyform}
            updateModalStateByChild={updateModalStateByChild}
            jobId={jobId}
          ></ApplyNowForm>
        )}
      </div>
    </React.Fragment>
  );
};

export default JobVacancyList;
