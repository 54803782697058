import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../../AppContext/AppContext";
import parse from 'html-react-parser';

// Function to replace <h1> with <p>
const replaceH1WithP = (htmlString) => {
  if(htmlString === null) {
    return;
  }
  return parse(htmlString, {
    replace: (domNode) => {
      if (domNode.name === 'h3' || domNode.name === 'h1' || domNode.name === 'h2' || domNode.name === 'h4') {
        return (
          <p>{domNode.children.map((child, index) => (child.data || child)).join('')}</p>
        );
      }
    },
  });
};

const PopularPost = () => {
  const { popularPosts } = useContext(AppContext);

  return (
    <React.Fragment>
      <div className="mt-4 pt-2">
        <div className="sd-title">
          <h6 className="fs-16 mb-3">Popular Post</h6>
        </div>
        <ul className="widget-popular-post list-unstyled my-4">
          {popularPosts?.slice(0, 4).map((popularPostDetails, key) => (
            <li
              className="d-flex mb-3 align-items-center pb-3 border-bottom"
              key={key}
            >
              <img
                src={popularPostDetails.image?.url}
                alt="image not available"
                className="widget-popular-post-img rounded"
              />
              <div className="flex-grow-1 text-truncate ms-3">
                <Link className="text-dark" to={`/blogdetails/${popularPostDetails._id}`}>
                  <p>
                    {replaceH1WithP(
                      popularPostDetails.content?.length > 25
                        ? `${popularPostDetails.content?.slice(0, 25) + "..."}`
                        : popularPostDetails.content
                    )}
                  </p>
                </Link>
                <span className="d-block text-muted fs-14">
                  {popularPostDetails.postdate}
                </span>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </React.Fragment>
  );
};

export default PopularPost;
