import React, { useContext, useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import Section from "../../../pages/Blog/BlogGrid/Section";
import BlogText from "../../Blog/BlogGrid/BlogText";
import PopularPost from "../../Blog/BlogGrid/PopularPost";
import BlogCategory from "../../Blog/BlogGrid/BlogCategory";
import Tags from "../../Blog/BlogGrid/Tags";
import SocialConnect from "../../Blog/BlogGrid/SocialConnect";
import { AppContext } from "../../../AppContext/AppContext";
import Pagination from "../../Jobs/JobList2/Pagination";

const BlogGrid = () => {
  document.title = "Blog Grid | Jobcy";

  const {
    resourcesList,
    searchResults,
    parentCategories,
    fetchBlogs,
    setSearchResults,
    popularPosts,
    sortByViews,
    childCategories,
  } = useContext(AppContext);

  const [query, setQuery] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 6; // Number of posts per page
  const [loading, setLoading] = useState(true); // Loading state
  const [hasError, setHasError] = useState(false); 

  useEffect(() => {
    fetchBlogs();
  }, []);

  useEffect(() => {
    sortByViews();
  }, [resourcesList]);

  useEffect(() => {
    handleSearch();
  }, [query, selectedCategories, selectedTags, resourcesList]);

   
useEffect(() => {
  const fetchJobs = async () => {
    setLoading(true);
    setHasError(false);
    try {
      await new Promise((resolve, reject) => {
        // Simulate a fetching error for demonstration
        const success = true; // Change to false to simulate an error
        setTimeout(() => {
          success ? resolve() : reject(new Error("Failed to fetch jobs"));
        }, 400);
      });
    } catch (error) {
      console.error(error);
      setHasError(true);
    } finally {
      setLoading(false);
    }
  };
  fetchJobs();
}, []);

  const handleSearch = () => {
    const results = resourcesList.filter((resource) => {
      const matchesQuery =
        resource.title?.toLowerCase()?.includes(query.toLowerCase()) ||
        resource.content?.toLowerCase()?.includes(query.toLowerCase());

      const matchesCategory =
        selectedCategories?.length === 0 ||
        selectedCategories.includes(resource?.parentCategory);

      const matchesTags =
        selectedTags?.length === 0 ||
        selectedTags.includes(resource?.category); // Assuming `resource.tag` holds the blog's tag

      return matchesQuery && matchesCategory && matchesTags;
    });
    setSearchResults(results);
    setCurrentPage(1); // Reset to the first page on search
  };

  const handleQueryChange = (newQuery) => {
    setQuery(newQuery);
  };

  const handleCategoryChange = (newCategories) => {
    setSelectedCategories(newCategories);
  };

  const handleTagSearch = (tags) => {
    setSelectedTags(tags);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scroll to top
    });
  };

  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = searchResults.slice(indexOfFirstPost, indexOfLastPost);

  return (
    <React.Fragment>
      <Section />
      <section className="section">
        <Container>
          <Row>
            <Col lg={8} md={7}>
              <div className="blog-post">
                {loading ? (
                    <div className="loader">
                      <div className="spinner"></div>
                    </div>
                  ) : hasError || !currentPosts? (
                    <div className="mt-4">No Jobs Found</div>
                  ) : 
                  currentPosts.length > 0 ? 
                  <BlogText searchResults={currentPosts} resourcesList={resourcesList} />
                  : 'No Blogs Found'
                }
                
              </div>
            </Col>
            <Col lg={4} md={5}>
              {
                resourcesList.length > 0 ? 
                <div className="sidebar ms-lg-4 ps-lg-4 mt-5 mt-lg-0">
                  <BlogCategory
                    onSearch={handleQueryChange}
                    parentCategories={parentCategories}
                    selectedCategories={selectedCategories}
                    setSelectedCategories={handleCategoryChange}
                  />
                  <PopularPost />
                  <Tags childCategories={childCategories} onSearch={handleTagSearch} />
                  <SocialConnect />
                </div>
                : ""
              }
              
            </Col>
          </Row>
        </Container>
        {
          searchResults.length > 0 ? 
          <Pagination 
          currentPage={currentPage} 
          postsPerPage={postsPerPage} 
          totalPosts={searchResults.length} 
          onPageChange={handlePageChange} 
          />
          : ""
        }

      </section>
    </React.Fragment>
  );
};

export default BlogGrid;
