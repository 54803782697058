import React, {useEffect, useState} from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

//Import Images
import teamMemberImage1 from "../../../assets/images/user/img-01.jpg";
import teamMemberImage2 from "../../../assets/images/user/img-02.jpg";
import teamMemberImage3 from "../../../assets/images/user/img-03.jpg";
import teamMemberImage8 from "../../../assets/images/user/img-08.jpg";
import teamMemberImage7 from "../../../assets/images/user/img-07.jpg";
import teamMemberImage4 from "../../../assets/images/user/img-04.jpg";
import teamMemberImage6 from "../../../assets/images/user/img-06.jpg";
import teamMemberImage10 from "../../../assets/images/user/img-10.jpg";
import teamMemberImage11 from "../../../assets/images/user/img-11.jpg";
import toast from "react-hot-toast";

import magicJson from "../../../magicJson/magicJson";


const TeamPage = () => {

  const [teamData, setTeamData] = useState();

  const getTeamdata = async () => {
    magicJson.endpoint = "company";
    magicJson.executor = "getAllMember";

    const data = new FormData();
    data.append("request", JSON.stringify(magicJson));

    const requestOptions = {
      method: "POST",
      body : data,
    };

    await fetch("https://demo-admin-hcms-api.akinolabs.com/service", requestOptions)
      .then((resp) => resp.json())
      .then((resp) => {
        let arr = [];
        for(let i=0; i<resp["data"][0].length; i++)
        {
          if(resp["data"][0][i]["currentlyActive"])
          {
            arr.push(resp["data"][0][i]);
          }
        }
        setTeamData(arr);
      });
  };

  useEffect(() => {
    getTeamdata();
  }, []);

  const handleClick = () => {
    toast.remove();
    toast.error("Work in progress!");
  }

  return (
    <React.Fragment>
      <section className="section">
        <Container>
          <Row>
            {teamData && teamData.map((teamPageDetails, key) => (
              <Col lg={4} md={6} key={key}>
                <div className="team-box card border-0 mt-4">
                  <div className="team-img position-relative mx-auto">
                    <img
                      src={teamPageDetails.image.url}
                      alt=""
                      className="img-thumbnail"
                    />
                    <ul className="team-social list-unstyled">
                      <li>
                        <Link to="#" onClick={handleClick}>
                          <i className="mdi mdi-facebook"></i>
                        </Link>
                      </li>
                      <li className="my-1">
                        <Link to="#" onClick={handleClick}>
                          <i className="mdi mdi-twitter"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="#" onClick={handleClick}>
                          <i className="mdi mdi-skype"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="team-content card-body text-center">
                    <Link to="/candidatedetails" className="primary-link">
                      <h6 className="fs-17 mb-1">
                        {teamPageDetails.employeeName}
                      </h6>
                    </Link>
                    <p className="text-muted mb-0">
                      {teamPageDetails.employeeRole}
                    </p>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default TeamPage;
