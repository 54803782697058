import React from "react";
import Select from "react-select";

const JobType = ({ jobVacancyList, onJobTypeChange }) => {
  const skillSet = new Set();
  if (jobVacancyList?.length) {
    jobVacancyList.forEach((job) => {
      job.skills.forEach((skill) => {
        skillSet.add(skill);
      });
    });
  }

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const options = Array.from(skillSet).map((skill, index) => ({
    label: capitalizeFirstLetter(skill),
    value: index.toString(),
  }));

  options.unshift({ label: "All skills", value: "all" });

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      border: 0,
      boxShadow: "none",
      padding: "12px 0 12px 40px",
      margin: "-16px -6px 0 -52px",
      borderRadius: "0",
    }),
  };

  const handleChange = (selectedOption) => {
    onJobTypeChange(selectedOption);
  };

  return (
    <React.Fragment>
      <Select
        options={options}
        styles={colourStyles}
        className="selectForm__inner"
        data-trigger
        defaultValue={{ label: "All skills", value: "all" }}
        name="choices-single-categories"
        id="choices-single-categories"
        aria-label="Default select example"
        onChange={handleChange}
      />
    </React.Fragment>
  );
};

export default JobType;
