import React, { useState, useEffect } from "react";
import { Container, Col, Row, Card } from "reactstrap";
import Section from "../BlogDetails/Section";
import BlogTitle from "../BlogDetails/BlogTitle";
import BlogCategory from "../BlogGrid/BlogCategory";
import PopularPost from "../BlogGrid/PopularPost";
import TextWidget from "../BlogGrid/TextWidget";
import Archives from "../BlogGrid/Archives";
import Tags from "../BlogGrid/Tags";
import SocialConnect from "../BlogGrid/SocialConnect";
import BlogSwiper from "../BlogDetails/BlogSwiper";
import BlogColumn from "../BlogDetails/BlogColumn";
import BlogComments from "../BlogDetails/BlogComments";
import BlogForm from "../BlogDetails/BlogForm";
import BlogPost from "../BlogDetails/BlogPost";
import { useParams } from "react-router-dom";
import magicJson from "../../../magicJson/magicJson";

const BlogDetails = () => {
  document.title = "Blog Details | Jobcy";

  const [blogDetails, setBlogDetails] = useState({});
  const [allBlogs, setAllBlogs] = useState([]);
  const [comments, setComments] = useState([]);

  const { id } = useParams();

  useEffect(() => {
    const fetchBlog = async () => {
      magicJson.endpoint = "resources";
      magicJson.executor = "getResourcesById";
      magicJson.data = [{ id }];

      const reqData = new FormData();
      reqData.append("request", JSON.stringify(magicJson));

      const requestOptions = {
        method: "POST",
        body: reqData,
      };

      const res = await fetch(process.env.REACT_APP_API_URL, requestOptions);
      const data = await res.json();
      setBlogDetails(data.data[0][0]);
      setComments(data.data[0][0]?.comments || []);
    };

    const fetchAllBlogs = async () => {
      magicJson.endpoint = "resources";
      magicJson.executor = "getResources";

      const reqData = new FormData();
      reqData.append("request", JSON.stringify(magicJson));
      const requestOptions = {
        method: "POST",
        body: reqData,
      };

      const res = await fetch(process.env.REACT_APP_API_URL, requestOptions);
      const data = await res.json();
      setAllBlogs(data.data[0]);
    };

    fetchAllBlogs();
    fetchBlog();
  }, [id]);

  const addComment = (newComment) => {
    setComments((prevComments) => [newComment, ...prevComments]);
  };

  return (
    <React.Fragment>
      <Section />
      <section className="section">
        <Container>
          <BlogTitle
            title={blogDetails?.title}
            category={blogDetails?.category}
          />
          <Row>
            <Col lg={8}>
              <div className="blog-post">
                {/* <BlogSwiper image={blogDetails.image} /> */}
                <Col lg={10} className="mb-4">
                  <Card
                    className="blog-grid-box blog-grid-box-style p-2"
                  >
                    <img
                      src={blogDetails.image?.url}
                      alt="image is not available"
                      className="object-fit-cover"
                      style={{ height: "400px" }}
                    />
                  </Card>
                </Col>

                <BlogColumn
                  author={blogDetails?.createdBy}
                  noOfComments={comments?.length || 0}
                  createdAt={blogDetails?.createdAt?.slice(0, 10)}
                  content={blogDetails?.content}
                />
                {
                  blogDetails?.allowComments === true ? 
                    <div>
                      <BlogForm addComment={addComment} />
                      <BlogComments comments={comments} />
                    </div>
                  : " "
                }
                <BlogPost allBlogs={allBlogs} blogDetails={blogDetails} />
              </div>
            </Col>
            <Col lg={4} md={5}>
              <div className="sidebar ms-lg-4 ps-lg-4 mt-5 mt-lg-0">
                {/* <BlogCategory /> */}
                <PopularPost />
                {/* <TextWidget /> */}
                {/* <Archives /> */}
                {/* <Tags /> */}
                <SocialConnect />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default BlogDetails;
