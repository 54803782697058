import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { AppContextProvider } from "./AppContext/AppContext";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.Fragment>
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <AppContextProvider>
          <Toaster
            toastOptions={{
              position: "top-center",
              duration: 5000,
              style: {
                margin: "6rem 0rem 0rem 0rem",

              },
            }}
          />
          <App />
      </AppContextProvider>
      
    </BrowserRouter>
  </React.Fragment>
);
