import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import {
  Modal,
  ModalBody,
  Input,
  Label,
  Form,
  FormGroup,
  FormFeedback,
  Button,
  Row,
  Col,
} from "reactstrap";
import { addMinutes, formatDistanceToNow } from "date-fns";
import aloisLogo from "../../../assets/images/logo/alois-logo.svg";
import magicJson from "../../../magicJson/magicJson";
import ApplyNowForm from "../JobList/applyNowform";

const JobVacancyList = () => {
  //Apply Now Modal
  const [modal, setModal] = useState(false);
  const [jobVacancyList, setJobVacancyList] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [resume, setResume] = useState(null);
  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [jobId, setJobId] = useState("");
  const [isApplyFormOpen, setIsApplyFormOpen] = useState(false);

  const openApplyForm = () => {
    setIsApplyFormOpen(true);
  };

  const closeApplyform = () => {
    setIsApplyFormOpen(false);
  };

  const updateModalStateByChild = (value) => {
    setIsApplyFormOpen(value);
  };


  const { id } = useParams();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const openModal = () => {
    setModal(!modal);
    setErrors({});
  };

  const handleApply = (id) => {
    openApplyForm()
    setJobId(id);
  };

  useEffect(() => {
    async function getJobs() {
      let data = new FormData();

      magicJson.endpoint = "jobs";
      magicJson.executor = "getActiveJobs";

      data.append("request", JSON.stringify(magicJson));
      let requestOptions = {
        method: "POST",
        body: data,
      };

      await fetch(process.env.REACT_APP_API_URL, requestOptions)
        .then((resp) => resp.json())
        .then((resp) =>
          setJobVacancyList(resp.data[0].filter((job) => job._id !== id))
        );
    }

    getJobs();
  }, [id]);

  const getTimeAgo = (createdAt) => {
    const date = new Date(createdAt);

    // IST is GMT+5:30 which is 330 minutes ahead
    const dateIST = addMinutes(date, 330);

    // Get the "time ago" string
    return formatDistanceToNow(dateIST, { addSuffix: true });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = "Name is required";
    if (!formData.email) newErrors.email = "Email is required";
    if (!resume) newErrors.resume = "Resume is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (validateForm()) {
      const data = new FormData();

      try {
        magicJson.endpoint = "apply";
        magicJson.executor = "createCandidate";
        magicJson.data = [
          {
            jobId,
            name: formData.name,
            email: formData.email,
            message: formData.message,
          },
        ];

        data.append("request", JSON.stringify(magicJson));
        data.append("files", resume);
        const requestOptions = {
          method: "POST",
          body: data,
        };

        fetch(process.env.REACT_APP_API_URL, requestOptions).then((resp) => {
          resp.json().then(() => {
            setFormData({ name: "", email: "", message: "" });
            setResume(null);
            openModal();
            toast.remove();
            toast.success("Applied Successfully!");
          });
        });
      } catch (error) {
        console.log("error", error.message);
      }
    } else {
      setSubmitted(true);
    }
  };

  const handleClick = () => {
    toast.remove();
    toast.error("Work in progress!");
  };

  return (
    <React.Fragment>
      <div>
        {jobVacancyList.map((jobVacancyListDetails, key) => (
          <div
            key={key}
            className={
              jobVacancyListDetails.addclassNameBookmark === true
                ? "job-box bookmark-post card mt-4"
                : "job-box card mt-4"
            }
          >
            {/* <div className="bookmark-label text-center" onClick={handleClick}>
              <Link to="#" className="align-middle text-white">
                <i className="mdi mdi-star"></i>
              </Link>
            </div> */}

            <div className="p-4">
              <Row>
                <Col md={2}>
                  <div className="text-center mb-4 mb-md-0">
                    <Link to="/companydetails">
                      <img
                        src={aloisLogo}
                        alt="company-logo"
                        className="img-fluid rounded-3"
                      />
                    </Link>
                  </div>
                </Col>

                <Col md={3}>
                  <div className="mb-2 mb-md-0">
                    <h5 className="fs-18 mb-0">
                      <Link
                        to={`/jobdetails/${jobVacancyListDetails._id}`}
                        className="text-dark"
                      >
                        {jobVacancyListDetails.title}
                      </Link>
                    </h5>
                    <p className="text-muted fs-14 mb-0">
                      {jobVacancyListDetails.companyName}
                    </p>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="d-flex mb-2">
                    <div className="flex-shrink-0">
                      <i className="mdi mdi-map-marker text-primary me-1"></i>
                    </div>
                    <p className="text-muted mb-0">
                      {[
                        jobVacancyListDetails.city,
                        jobVacancyListDetails.state,
                        jobVacancyListDetails.country,
                      ]
                        .filter(Boolean) // Filter out empty values
                        .map((part, index, array) => (
                          // Add <br /> between parts except the last one
                          <React.Fragment key={index}>
                            {part}
                            {index < array.length - 1 && ","}
                            {index < array.length - 1 && <br />}
                          </React.Fragment>
                        ))}
                    </p>
                  </div>
                </Col>


                <Col md={3}>
                  <div className="d-flex mb-0">
                    <div className="flex-shrink-0">
                      <i className="uil uil-clock-three text-primary me-1"></i>
                    </div>
                    <p className="text-muted mb-0">
                      {getTimeAgo(jobVacancyListDetails.createdAt)}
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="p-3 bg-light-gray">
              <Row className="justify-content-between">
                <Col md={4}>
                  <div>
                    <p className="text-muted mb-0">
                      <span className="text-dark">Experience: </span>
                      {jobVacancyListDetails.minExp}-
                      {jobVacancyListDetails.maxExp} Years
                    </p>
                  </div>
                </Col>
                <Col lg={2} md={3}>
                  <div>
                    <Link
                      to="#"
                      onClick={() => handleApply(jobVacancyListDetails._id)}
                      // to="#applyNow"
                      // onClick={() => handleApply(jobVacancyListDetails._id)}
                      className="primary-link"
                    >
                      Apply Now <i className="mdi mdi-chevron-double-right"></i>
                    </Link>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        ))}

        <div className="text-center mt-4">
          <Link to="/joblist" className="primary-link form-text">
            View More <i className="mdi mdi-arrow-right"></i>
          </Link>
        </div>

        {isApplyFormOpen && (
          <ApplyNowForm
            show={isApplyFormOpen}
            onClose={closeApplyform}
            updateModalStateByChild={updateModalStateByChild}
            jobId={jobId}
          ></ApplyNowForm>
        )}
      </div>
    </React.Fragment>
  );
};

export default JobVacancyList;
