import React, { useState } from "react";
import { Input, Form } from "reactstrap";

const BlogCategory = ({ onSearch, parentCategories, selectedCategories, setSelectedCategories }) => {
  const [query, setQuery] = useState("");

  const handleQueryChange = (e) => {
    e.preventDefault();
    const newQuery = e.target.value;
    setQuery(newQuery);
    onSearch(                                       newQuery);
  };

  const handleCategoryChange = (e) => {
    const { value, checked } = e.target;
    const newCategories = checked
      ? [...selectedCategories, value]
      : selectedCategories.filter((category) => category !== value);
    setSelectedCategories(newCategories);
  };
  
  const handleFormSubmit = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
  };

  return (
    <React.Fragment>
      <aside className="widget widget_search">
        <Form className="position-relative" onSubmit={handleFormSubmit}>
          <Input
            className="form-control"
            type="search"
            placeholder="Search..."
            value={query}
            onChange={handleQueryChange}
          />
          <button
            className="bg-transparent border-0 position-absolute top-50 end-0 translate-middle-y me-4"
            type="submit"  style={{ marginTop: "2px" }} 
          >
            <span className="mdi mdi-magnify text-muted"></span>
          </button>
        </Form>
      </aside>
      <div className="mt-4 pt-2">
        <div className="sd-title">
          <h6 className="fs-16 mb-3">Categories</h6>
        </div>
        <div className="my-3">
          {parentCategories.map((category) => (
            <div className="form-check mb-2" key={category}>
              <input
                className="form-check-input border-color"
                type="checkbox"
                value={category}
                checked={selectedCategories?.includes(category)}
                onChange={handleCategoryChange}
              />
              <label className="form-check-label ms-2" htmlFor={category}>
                {category.charAt(0).toUpperCase() + category.slice(1)}
              </label>
            </div>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};

export default BlogCategory;
